import React, { useEffect } from 'react';
import Layout from '../components/layout';
import SEO from "../components/seo";

const GetInvolved = () => {
    const seo = {
        metaDesc: 'Get Involved - Inventiv.org'
    }

    useEffect(() => {
        document?.body.classList.add('no-home');
    }, []);

    return (
        <Layout activeMenu={'involved'}>
            <SEO title="Get Involved - Inventiv.org" canonical='/get-involved' seo={seo} />

            <div class="sub-banner overview-bgi">
                <div class="container">
                    <div class="breadcrumb-area">
                        <h1>Get Involved</h1>
                    </div>
                </div>
            </div>

            <section class="mt-3 mb-3 pt-3 pb-3 wow fadeInUp delay-04s">
                <div class="container">
                    <div class="row">
                        <div class="col-12 col-md-12 col-lg-3 ">
                            <p class="sec-clr bold f-16">
                                At Inventiv.org, we believe that everyone has the power to make a difference. Join our global community of inventors, dreamers, and changemakers and get involved in shaping a better future. Here are some meaningful ways you can contribute:
                            </p>
                        </div>
                        <div class="col-12 col-md-12 col-lg-9">
                            <div class="row">
                                <div class="col-12 col-md-6 col-lg-4 ">
                                    <h3 class="sec-clr">
                                        Become an Inventor
                                    </h3>
                                    <p class="sec-clr f-16">
                                        Unleash your creativity and join the ranks of inventors who are pushing the boundaries of what’s possible. Whether you have a groundbreaking idea or a passion for problem-solving, we provide the resources, support, and mentorship to help you bring your inventions to life. Dive into our educational programs, workshops, and collaborative spaces and embark on an inventive journey that can change lives.
                                    </p>
                                </div>
                                <div class="col-12 col-md-6 col-lg-4 ">
                                    <h3 class="sec-clr">
                                        Volunteer Your Skills
                                    </h3>
                                    <p class="sec-clr f-16">
                                        Share your expertise and become a mentor to aspiring inventors. Your guidance and experience can make a profound impact on their inventive journeys. Additionally, we welcome volunteers who can contribute their skills in areas such as event planning, marketing, fundraising, or content creation. By volunteering your time and talents, you become an essential part of our mission to empower inventors and drive innovation.
                                    </p>
                                </div>
                                <div class="col-12 col-md-6 col-lg-4 ">
                                    <h3 class="sec-clr">
                                        Spread the Word
                                    </h3>
                                    <p class="sec-clr f-16">
                                        Help us amplify our message and inspire others to get involved. Share our stories, initiatives, and success stories through your social networks, blogs, or community groups. By spreading awareness about the power of invention and the resources available at Inventiv.org, you can ignite the spark of creativity in others and encourage them to join our mission.
                                    </p>
                                </div>
                                <div class="col-12 col-md-6 col-lg-6 mt-1">
                                    <h3 class="sec-clr">
                                        Collaborate

                                    </h3>
                                    <p class="sec-clr f-16">
                                        We believe that collaboration is at the heart of innovation. If you or your organization shares our passion for invention and impact, let’s collaborate! We welcome partnerships, joint initiatives, and interdisciplinary collaborations that aim to address global challenges. Together, we can leverage our collective strengths and drive transformative change.
                                    </p>
                                </div>
                                <div class="col-12 col-md-6 col-lg-6 mt-1">
                                    <h3 class="sec-clr">
                                        Support Inventors
                                    </h3>
                                    <p class="sec-clr f-16">
                                        Celebrate and support inventors in your community and beyond. Recognize their accomplishments, engage with their work, and provide resources or expertise to help them succeed. By championing inventors, you contribute to a culture that values and nurtures innovation, creating an environment where groundbreaking ideas thrive.
                                    </p>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </section>

            <section class="mt-3 mb-3 pt-3 pb-3 bg-light-blue">
                <div class="container">
                    <div class="row">
                        <div class="col-12 col-md-6 wow fadeInLeft delay-04s">
                            <img src="assets/img/banner/patent-work_.webp" width="100%" alt=""/>
                        </div>
                        <div class="col-12 col-md-6 wow fadeInRight delay-04s">
                            <p class="sec-clr f-16">
                                At Inventiv.org, we are building a global movement of inventors and supporters who are dedicated to creating a more innovative and sustainable world. Your involvement, no matter how big or small, can make a profound impact. Together, let’s unlock the power of invention and shape a future that is filled with possibilities.
                                <br/><br/>
                                    Get involved with Inventiv.org today and be a part of a community that celebrates creativity, drives change, and empowers inventors to make a lasting impact. Join us in unleashing the inventors within us all.

                                </p>
                                </div>
                        </div>
                    </div>
            </section>
        </Layout>
    )
};

export default GetInvolved;